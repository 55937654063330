<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="礼物名称" prop="giftName" >
        <a-input v-model="form.giftName" placeholder="请输入礼物名称" />
      </a-form-model-item>
      <a-form-model-item label="价格" prop="price" >
        <a-input-number v-model="form.price" placeholder="请输入价格" style="width: 150px" />
      </a-form-model-item>
      <a-form-model-item label="礼物分类" prop="giftClass" >
        <a-select placeholder="请选择礼物分类" v-model="form.giftClass">
          <a-select-option :value="0">
            静态礼物
          </a-select-option>
          <a-select-option :value="1">
            动态礼物
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="礼物封面图" prop="giftPath" >
        <a-upload
          listType="picture-card"
          class="avatar-uploader"
          :showUploadList="false"
          :customRequest='fileUploadRequest'
          :beforeUpload="beforeUpload"
          @change="handleChange"
        >
          <img v-if="form.giftPath" :src="form.giftPath" alt="图片" style="height:104px;max-width:300px"/>
          <div v-else>
            <a-icon :type="uploadLoading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">上传</div>
          </div>
        </a-upload>
      </a-form-model-item>
      <svga-upload
        v-if="form.giftClass ==1"
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="移动端礼物特效"
        :value="form.giftSvga"
        @change="svgaCallBack"
      />
<!--      <svga-upload2-->
<!--        v-if="form.giftClass ==1"-->
<!--        :labelCol="labelCol"-->
<!--        :wrapperCol="wrapperCol"-->
<!--        label="pc端特效地址"-->
<!--        :value="form.giftPcSvga"-->
<!--        @change="svgaCallBack2"-->
<!--      />-->
      <a-form-model-item label="角标标题" prop="giftSubscriptTitle" >
        <a-input v-model="form.giftSubscriptTitle" placeholder="请输入礼物角标标题"  />
      </a-form-model-item>
      <a-form-model-item label="角标图" prop="giftSubscript"  >
          <a-upload
            listType="picture-card"
            class="avatar-uploader image-container"
            :showUploadList="false"
            :customRequest='fileUploadRequest'
            :beforeUpload="beforeUpload"
            @change="handleChange1"
          >

               <img  v-if="form.giftSubscript" :src="form.giftSubscript" alt="图片" style="height:25px;max-width:300px"  @mouseover="showMaskImg"  @mouseout="hideMaskImg"  />
            <div v-else>
              <a-icon :type="uploadLoading4 ? 'loading' : 'plus'"/>
              <div class="ant-upload-text">上传</div>
            </div>
        </a-upload>
        <span v-if="form.giftSubscript" @click="delGiftSubscript()" style="cursor:pointer;">
          <a-icon    type="delete"  style="margin-left: 3px" /> 删除
        </span>

      </a-form-model-item>
      <a-form-model-item label="是否上架" prop="status" >
        <a-select placeholder="请选择是否上架" v-model="form.status">
          <a-select-option :value="0">
            否
          </a-select-option>
          <a-select-option :value="1">
            是
          </a-select-option>
        </a-select>
      </a-form-model-item>
<!--      <a-form-model-item label="排序" prop="sort" >-->
<!--        <a-input-number v-model="form.sort" placeholder="请输入排序" style="width: 150px" />-->
<!--      </a-form-model-item>-->
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getGift, addGift, updateGift } from '@/api/biz/gift'
import UploadFileToOSS from "@/utils/upload-file-to-oss";
import SvgaUpload from "@/components/SvgaUpload.vue";
import SvgaUpload2 from "@/components/SvgaUpload2.vue";

export default {
  name: 'CreateForm',
  props: {
    isAllAnnouncementOptions: {
      type: Array,
      required: true
    },
    giftClassOptions: {
      type: Array,
      required: true
    },
    statusOptions: {
      type: Array,
      required: true
    }
  },
  components: {
    SvgaUpload,
    SvgaUpload2
  },
  data () {
    return {
      showMask: false,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        giftName: null,

        originName: null,

        price: null,

        isAllAnnouncement: 0,

        giftClass: null,

        giftPath: null,

        giftSvga: null,

        giftPcSvga: null,

        wealthLevelId: null,

        status: 0,

        createTime: null,
        sort:null,
        giftSubscriptTitle:null,
        remark: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        giftName: [
          { required: true, message: '礼物名称不能为空', trigger: 'blur' }
        ],
        price: [
          { required: true, message: '价格不能为空', trigger: 'blur' }
        ],
        giftClass: [
          { required: true, message: '礼物分类不能为空', trigger: 'blur' }
        ],
        giftPath: [
          { required: true, message: '礼物封面图不能为空', trigger: 'blur' }
        ],
        status: [
          { required: true, message: '是否上架不能为空', trigger: 'blur' }
        ]

      },
      uploadLoading: false,
      uploadLoading4: false,
      uploadData: 0
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    svgaCallBack(value){
      this.form.giftSvga = value;
    },
    svgaCallBack2(value){
      this.form.giftPcSvga = value;
    },

    //图片遮罩层
    showMaskImg() {
      this.showMask = true;
      // // 如果需要调整遮罩层的位置以覆盖图片，可以在这里获取图片尺寸并计算定位
      // const { left, top } = this.$refs.imageElement.getBoundingClientRect();
      // // 例如设置CSS变量以动态调整遮罩层位置
      // this.$el.style.setProperty('--mask-left', `${left}px`);
      // this.$el.style.setProperty('--mask-top', `${top}px`);
    },
    hideMaskImg() {
      this.showMask = false;
    },
    deleteImage() {
      if (window.confirm('Are you sure you want to delete this image?')) {
        // 这里可以添加实际的删除逻辑，例如调用API删除图片
        console.log('Image deleted');
        this.showMask = false; // 删除后关闭遮罩
      }
    },

    fileUploadRequest(fileInfo) {
      this.uploadLoading = true

      new UploadFileToOSS([fileInfo.file], {
        finally: (res) => {
          // Log.info('multi-img-upload', res);
          const ossUrl = res[0].url;
          fileInfo.file.ossUrl = ossUrl;
          fileInfo.file.ossName = ossUrl;
          fileInfo.onSuccess();
        },
      });
    },
    beforeUpload: function (file) {
      return new Promise((resolve, reject) => {
        if(file.size>5*1024*1000){
          this.$message.warning("图片大小不能超过5M----")
          return reject(false)
        }

        var fileType = file.type;
        if (fileType.indexOf('image') < 0) {
          this.$message.warning('请上传图片');
          return reject(false);
        }
        resolve(true)
      });
    },
    handleChange(info) {
      if (info.file.status === 'uploading') {
        this.uploadLoading = true
        return
      }
      if (info.file.status === 'done') {
        // let response = info.file.response;
        this.uploadLoading = false;
        this.form.giftPath = info.file.originFileObj.ossUrl;
      }
    },
    handleChange1(info) {
      if (info.file.status === 'uploading') {
        this.uploadLoading4 = true
        return
      }
      if (info.file.status === 'done') {
        // let response = info.file.response;
        this.uploadLoading4 = false;
        this.form.giftSubscript = info.file.originFileObj.ossUrl;
      }
    },

    //删除角标图片路径
    delGiftSubscript(){
      debugger
      this.uploadLoading4 = false;
      this.form.giftSubscript = '';
    },


    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        giftName: null,
        originName: null,
        price: null,
        isAllAnnouncement: 0,
        giftClass: null,
        giftPath: null,
        giftSvga: null,
        giftPcSvga: null,
        wealthLevelId: null,
        status: 0,
        createTime: null,
        remark: null
      }
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getGift({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateGift(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addGift(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
