import request from '@/utils/request'


// 查询礼物信息列表
export function listGift(query) {
  return request({
    url: '/biz/gift/list',
    method: 'get',
    params: query
  })
}

// 查询礼物信息分页
export function pageGift(query) {
  return request({
    url: '/biz/gift/page',
    method: 'get',
    params: query
  })
}

// 查询礼物信息详细
export function getGift(data) {
  return request({
    url: '/biz/gift/detail',
    method: 'get',
    params: data
  })
}

// 新增礼物信息
export function addGift(data) {
  return request({
    url: '/biz/gift/add',
    method: 'post',
    data: data
  })
}

// 修改礼物信息
export function updateGift(data) {
  return request({
    url: '/biz/gift/edit',
    method: 'post',
    data: data
  })
}

// 删除礼物信息
export function delGift(data) {
  return request({
    url: '/biz/gift/delete',
    method: 'post',
    data: data
  })
}
// 设置置顶
export function setTop(data) {
  return request({
    url: '/biz/gift/setTop',
    method: 'get',
    params: data
  })
}

