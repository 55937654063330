<template>
  <div>
<!--    <a-button @click="playSvga(value)">播放特效</a-button>-->

      <div :id="'svgaBox2'+cid" style="width: 200px;height: 200px;"></div>

<!--    <div :src="this.url.imgerver +  '/' + value" :id="'svgaBox'+cid" style="width: 200px;height: 200px;"></div>-->

  </div>


</template>

<script>
import SVGA from 'svgaplayerweb'

export default {
  name: 'SvgaPlayer',
  data(){
    return{
      visible:false,
      svgaUrl:"",
      url:{
      }
    }
  },
  props: {
    value: {
      type: String,
      required: true
    },
    cid:{
      type:String,
      required: false,
      default:""
    }
  },
  created(){

  },
  // created () {
  //   new SVGA.autoload(document.querySelector('svgaBox'+cid))
  // },
  mounted () {
    this.playSvga(this.value);
  },
  methods:{
    playSvga(mallGif){
      console.log("mallGif:"+JSON.stringify(mallGif));
      let label = document.getElementsByTagName('div')[0];
      let player = new SVGA.Player('#svgaBox2'+this.cid);
      let parser = new SVGA.Parser('#svgaBox2'+this.cid);
      parser.load(mallGif, function(videoItem) {
        // player.loops = 10;  // 设置循环播放次数是1
        player.setVideoItem(videoItem);
        player.startAnimation();
      });
    },
    handleCancel(e) {
      this.visible = false;
    },
  },
  watch: {
    value: {
      immediate:true,
      handler(v){
        if (v) {
          this.svgaUrl = v;
        } else {
          this.svgaUrl = "";
        }
        this.playSvga(this.svgaUrl);
      }

    },
    cid(v) {
      if (v) {
        this.cid = v;
      } else {
        this.cid = "";
      }
    },
  },

}
</script>

<style scoped>

</style>